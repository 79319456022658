import { createWebHistory, createRouter } from "vue-router"

// General Views
import Organization from '@/pages/OrganizationPage.vue'
import Credentials from '@/pages/CredentialsPage.vue'
import NotFound from '@/pages/NotFoundPage.vue'
import CheckEmail from '@/pages/CheckEmailPage.vue'
import Confirmation from '@/pages/ConfirmationPage.vue'
import SignUpEnd from '@/pages/SignUpEndPage.vue'

export const routes = [
  { path: '/', redirect: '/signup/select-organization' },
  { path: '/signup/select-organization', name: 'Organization', component: Organization },
  { path: '/signup/credentials', name: 'Credentials', component: Credentials },
  { path: '/check-email', name: 'CheckEmail', component: CheckEmail },
  { path: '/signup/confirmation', name: 'Confirmation', component: Confirmation },
  { path: '/signup-end', name: 'SignUpEnd', component: SignUpEnd },
  { path: '/:catchAll(.*)', name: 'NotFound', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
