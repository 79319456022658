import { defineStore } from 'pinia'
import AuthService from '@/services/auth.service'

const userInitialState = {
  token: '',
  roles: [],
  role: '',
  username: '',
  password: '',
  confirmPassword: ''
}

export const useConfirmationStore = defineStore('confirmation', {
  state: () => ({
    user: userInitialState,
  }),
  getters: {
    getUser: (state) => { return state.user } 
  },
  actions: {
    async fetchByToken(token) {

      await AuthService.getUserByToken(token)
        .then(response => {
          this.user = response.data
        })
        .catch(error => {
          return Promise.reject(error)
        })

      return Promise.resolve(this.user)
    },
    async register(user) {

      await AuthService.register(user)
        .then(response => {
          this.user = response.data
        })
        .catch(error => {
          return Promise.reject(error)
        })

      return Promise.resolve(this.user)
    },
  }
})
