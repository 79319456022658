import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
    state: () => ({
        errors: [],
        messages: []
    }),
    getters: {
        // computed properties...
    },
    actions: {
        setError(payload) {
            this.errors.push(payload)
        },
        setErrors(errors) {
            this.errors = errors
        },
        setMessage(payload) {
            this.messages.push(payload)
        },
        setMessages(messages) {
            this.messages = messages
        },
        reset() {
            this.errors = []
            this.messages = []
        }
    }
})